#scrollUp {
  position: fixed;
  width: 44px;
  height: 36px;
  background-color: transparentize($green, 0.2);
  border-radius: 5px;
  right: 10px;
  bottom: -40px;
  display: none;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  z-index: 20;
  &:hover {
    background: $green;
  }
  .upButton {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -3px;
    margin-left: -4px;
    opacity: 1;
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid $white;
    border-left: 2px solid $white;
    transform: rotate(45deg);
  }
}
