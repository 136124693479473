//fonts
$open-sans: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$roboto: 'Roboto', sans-serif;

// colors
$base-color: rgba(68, 68, 68, 1); //#444

$black: rgba(0, 0, 0, 1); //#000
$white: rgba(255, 255, 255, 1); //#fff

$orange: rgba(229, 140, 37, 1); //#E58C25

$red: rgba(255, 0, 0, 1); //#FF0000

$yellow: rgba(255, 255, 0, 1); //#FFFF00

$green: rgba(101, 188, 94, 1); //#65BC5E
$light-green: rgba(147, 193, 164, 1); //#93C1A4
$dark-green: rgba(0, 120, 69, 1); //#007845
$deep-green: rgba(88, 168, 64, 1); //#58A840

$blue: rgba(62, 115, 184, 1); //#3E73B8
$light-blue: rgba(100, 147, 208, 1); //#6493D0
$cadetblue: rgba(0, 169, 157, 1); //#00A99D

$purple: rgba(136, 109, 178, 1); //#886DB2
$deep-purple: rgba(83, 46, 144, 1); //#532E90

$gray: rgba(128, 128, 128, 1); //#808080
$light-gray: rgba(221, 221, 221, 1); //#DDD
