/*compare table*/
.compare-table__header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  border-bottom: 1px solid $light-gray;
  display: none;
}

.compare-table__header-mobile {
  margin-bottom: 10px;
  font-weight: 600;
}

.compare-table__row {
  margin-bottom: 20px;

  [class*="col-"] {
    padding-bottom: 20px;
  }
}

.compare-table__row-aspect {
  font-weight: 700;
  padding: 10px 0;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
}

@media (min-width: 768px) {
  .compare-table__header {
    display: block;
  }

  .compare-table__header-mobile {
    display: none;
  }

  .compare-table__row {
    border-bottom: 1px solid $light-gray;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .compare-table__row-aspect {
    font-weight: 400;
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
}
