/*solutions*/
.solutions {
  background-color: $blue;
  color: $white;

  h2 {
    color: inherit;
  }

  h5 {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $light-blue;
    color: inherit;
    text-align: center;
  }

  a {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.solutions__bot {
  background: url("../img/clipart-people.png") no-repeat center;
  padding: 20px 0 0 0;

  img {
    height: 160px;
  }
}

.solutions__icon {
  font-size: 60px;
  display: block;
  text-align: center;
  padding-bottom: 26px;
}
