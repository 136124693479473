/*screenshots*/
.screenshots {
  color: $white;
  background-color: $deep-purple;

  h2 {
    color: inherit;
  }
}

.screenshots__slider {
  max-width: 910px;
  padding: 0;
  margin: 0 auto;

  h5 {
    margin-bottom: 15px;
    color: inherit;
    text-align: center;
    line-height: 1.65;
  }

  .slick-dots li.slick-active button:before, .slick-dots li button:before {
    color: $white;
  }
}

@media (min-width: 768px) {
  .screenshots__slider {
    padding: 0 55px;

    h5 {
      height: 120px;
    }
  }
}

@media (min-width: 992px) {
  .screenshots__slider {
    h5 {
      height: 90px;
    }
  }
}
