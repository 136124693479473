/*contact*/
.contact {
  padding-bottom: 0;

  ul {
    list-style: none;
    padding: 0;
  }
}

.contact__content {
  text-align: center;
  background-color: $white;
  padding-bottom: 60px;

  a {
    color: $green;
  }

  img {
    height: 160px;
  }

  ul {
    margin-bottom: 60px;
  }
}

.contact__bot {
  margin-bottom: 60px;

  img {
    margin-left: 45px;
  }
}

.contact__form {
  padding: 60px 15%;
  background-color: $deep-purple;

  input, textarea {
    background-color: transparent;
    width: 100%;
    padding-bottom: 15px;
    border: none;
    border-bottom: 1px solid $purple;
    color: $white;

    &:focus {
      border-bottom: 1px solid $white;
      outline: none;
    }
  }

  textarea {
    height: 110px;
  }

  label {
    font-size: 14px;
    color: $light-green;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .form-group {
    margin: 10px 0 20px;

    &:last-of-type {
      margin-bottom: 70px;
    }
  }

  .captcha__question {
    margin-bottom: 10px;
    line-height: 20px;
    color: $gray;
  }
}

.contact__form-controls {
}

@media (min-width: 768px) {
  .contact {
    padding-bottom: 60px;
    background: linear-gradient(to right, $white 0%, $white 50%, $deep-purple 50%, $deep-purple 100%);
  }
}
