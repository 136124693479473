/*header*/

header {
  height: 60px;
}

.navbar {
  height: 60px;
  font-family: $roboto;
  padding: 0 3rem;
}

.navbar.navbar-light {
  background: $white;
  font-size: 14px;

  .nav-link {
    color: $gray;
  }
}

.navbar-collapse {
  max-height: calc(100vh - 70px);
  padding: 0 20px;
}

a.nav-link {
  text-transform: uppercase;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    color: $green !important;
  }
}

.nav-item.active > a {
  color: $green !important;
}

.nav-link, .dropdown-item > a {
  display: flex;
  align-items: center;
  color: $gray;

  i {
    padding-right: 5px;
  }
}

.dropdown-item {
  & > a:hover {
    color: $green !important;
  }

  &:active, &.active {
    background-color: darken($gray-100, 5%);

    & > a {
      color: $green !important;
    }
  }
}

.nav-link {
  > i {
    font-size: 22px;
  }
}

.navbar-brand, .navbar-brand-clone {
  height: 60px;
  width: 120px;
  padding: 0;

}

@media (min-width: 1200px) {
  header {
    .navbar-collapse {
      padding: 0;
    }
  }
}

.nav-item.dropdown {
  &:hover > .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    margin-top: 0;
  }
}

.dropdown-submenu {
  position: relative;

  & > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    border-radius: 0 6px 6px 6px;
  }

  &:hover > .dropdown-menu {
    display: block;
  }

  & > a:after {
    display: block;
    position: absolute;
    content: " ";
    right: 10px;
    top: 12px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
  }

  &:hover > a:after {
    border-left-color: #fff;
  }

  .pull-left {
    float: none;

    .dropdown-menu {
      left: -100%;
      margin-left: 10px;
      border-radius: 6px 0 6px 6px;
    }
  }
}

@media (min-width: 1200px) {
  header {
    .navbar-collapse {
      padding: 0;
    }
  }
}
