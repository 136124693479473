/*animations*/
.fadeIn, .fadeIn-on-ready {
  opacity: 0;
}
.js-ready {
  .fadeIn-on-ready {
    animation: fadein 0.6s linear forwards;
  }
}
.fadeIn.show {
  animation: fadein 0.6s linear forwards;
}
