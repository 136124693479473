/*features*/
.features {
  padding-top: 0;

  h2 {
    padding-top: 60px;
    border-top: 1px solid $green;
  }

  h5 {
    padding-bottom: 30px;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid $green;
  }

  p {
    margin-bottom: 50px;
  }
}

.features__bot {
  padding: 0 30px;
  text-align: center;

  img {
    height: 160px;
  }
}

@media (min-width: 992px) {
  .features__bot {
    text-align: left;
  }
}

@media (min-width: 1400px) {
  .features__bot {
    padding: 0 80px;
  }
}
