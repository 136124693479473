/*overview*/
.overview__description {
  margin: 30px auto 50px;
  max-width: 345px;
  color: $black;
}

.overview__icon {
  max-width: 90px;
  max-height: 90px;
}
