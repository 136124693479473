/*icons*/

.arrow-down {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 25px;
    background-color: $white;
    border-radius: 5px;
  }

  &::before {
    right: 0;
    transform: rotate(48deg);
  }

  &::after {
    left: 0;
    transform: rotate(-48deg);
  }
}
