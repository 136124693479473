/*alternative grid*/

.alt-grid {
  &.container {
    max-width: 1800px;
  }

  &.container, &.fluid-container {
    padding: 0;
  }

  .row {
    margin-left: 0;
    margin-right: 0
  }

  [class*="col-"] {
    padding-left: 0;
    padding-right: 0
  }
}
