/*
Flaticon icon font: Flaticon
Creation date: 16/07/2018 07:15
*/

@font-face {
  font-family: "Flaticon";
  src: url("/assets/fonts/flaticon/Flaticon.eot");
  src: url("/assets/fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/flaticon/Flaticon.woff") format("woff"),
  url("/assets/fonts/flaticon/Flaticon.ttf") format("truetype"),
  url("/assets/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/assets/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-partner:before { content: "\f100"; }
.flaticon-government:before { content: "\f101"; }
.flaticon-chart:before { content: "\f102"; }
.flaticon-supply:before { content: "\f103"; }
.flaticon-enterprise:before { content: "\f104"; }
.flaticon-jigsaw:before { content: "\f105"; }
.flaticon-service:before { content: "\f106"; }
.flaticon-price:before { content: "\f107"; }
.flaticon-phone-book:before { content: "\f108"; }
.flaticon-settings:before { content: "\f109"; }
.flaticon-shapes:before { content: "\f10a"; }
.flaticon-users:before { content: "\f10b"; }
.flaticon-computing-cloud:before { content: "\f10c"; }

    $font-Flaticon-partner: "\f100";
    $font-Flaticon-government: "\f101";
    $font-Flaticon-chart: "\f102";
    $font-Flaticon-supply: "\f103";
    $font-Flaticon-enterprise: "\f104";
    $font-Flaticon-jigsaw: "\f105";
    $font-Flaticon-service: "\f106";
    $font-Flaticon-price: "\f107";
    $font-Flaticon-phone-book: "\f108";
    $font-Flaticon-settings: "\f109";
    $font-Flaticon-shapes: "\f10a";
    $font-Flaticon-users: "\f10b";
    $font-Flaticon-computing-cloud: "\f10c";