footer {
  color: #fff;

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.footer__nav-container {
  background-color: lighten($dark-green, 10%);
  padding: 30px 0 15px 0;
}

.footer__nav {
  font-family: $roboto;

  a:hover {
    padding-bottom: 10px;
  }

  > ul {
    display: flex;
    justify-content: space-between;

    > li {
      > a {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 20px;
      }

      > ul > li {
        > ul > li {
          padding-left: 15px;
        }
      }
    }
  }
}

.footer__contact-container {
  background-color: $dark-green;
  padding: 15px 0;
}

.footer__contact {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer__contact-list {
  ul {
    display: flex;
    margin-bottom: 0;
  }

  li {
    padding: 0 10px;
  }
}

.footer__copyright {
  margin-bottom: 15px;
}

.footer__contact-list-country {
  display: none;
}

.footer__icon-twitter {
  filter: invert(100%);
}

@media (min-width: 576px) {
  .footer__contact-list {
    li {
      padding: 0 20px;
    }
  }

  .footer__contact-list-country {
    display: flex;
  }
}

@media (min-width: 768px) {
  .footer__contact {
    align-items: inherit;
    justify-content: space-between;
    flex-direction: row;
  }

  .footer__copyright {
    margin-bottom: 0;
  }
}
