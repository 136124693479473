/*validate*/
.valid-error {
  color: $red !important;

  &input {
    border: 1px solid $red !important;
  }
}

.ajax-form__submit_disabled {
  cursor: progress;
}

.ajax-form__message {
  margin-top: 20px;
  padding: 10px;
  background-color: $white;
  text-align: center;
  border-radius: 10px;
  display: none;
}

.ajax-form__message_success {
  border: 2px solid $green;
}

.ajax-form__message_error {
  border: 2px solid $red;
}

.ajax-form__message_pending {
  border: 2px solid $yellow;
}
