/*description*/
.description-table {
  a {
    color: $base-color;

    &:hover {
      color: darken($base-color, 30%);
      text-decoration: none;
    }
  }
}

.description-table__header {
  margin-bottom: 20px;
}

.description-table__row {
  padding: 20px 0;
  border-bottom: 1px solid $light-gray;

  h2 {
    margin-bottom: 20px;
    text-align: left;
    display: none;
  }

  a {
    display: inline-block;
    vertical-align: middle;
  }

  [class*="col-"] {
    padding-bottom: 10px;
  }
}

.description-table__row-img {
  max-width: 160px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;
}

@media (min-width: 768px) {
  .description-table__row {
    border: none;

    h2 {
      display: block;
    }
  }

  .description-table__row-img {
    border: 1px solid $light-gray;
    min-height: 122px;
  }
}
