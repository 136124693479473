.use-case-header {
  padding: 40px 0 20px;
  text-align: center;
  h1 {
    margin-bottom: 40px;
  }
}

.use-case-description {
  a {
    color: $base-color;
    &:hover {
      color: darken($base-color, 30%);
      text-decoration: underline;
      text-decoration-style: dashed;
    }
  }
}

.use-case-container {
  >div {
    margin-bottom: 60px;
    background: #f8f9f8;
    padding: 40px 40px;
    border-radius: 10px;
  }
}

.use-case-controls {
  padding-top: 30px;
  text-align: center;
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (min-width: 576px) {
  .use-case-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .use-case-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .use-case-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .use-case-container {
    max-width: 1140px;
  }
}

.use-case-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  h3 {
    margin-bottom: 20px;
    font-size: 30px;
  }
}

ul.numbered-list {
  counter-reset: li;
  list-style-type: none;
  font-size: 15px;
  padding-left: 10px;
  columns: 2;
  column-rule: 1px solid lightblue;
  li {
    position: relative;
    padding: 5px 0 5px 30px;
    display: flex;
    align-items: center;
    min-height: 80px;
    &:before {
      content: counter(li);
      counter-increment: li;
      padding: 12px;
      font-size: 20px;
      border: 1px solid #1b6ab0;
      background-color: #1b6ab0;
      border-radius: 50%;
      color: white;
      line-height: 0.6;
      margin-right: 15px;
      text-align: center;
      left: 0;
      top: 4px;
    }
  }
}

ul.project-phases {
  display: flex;
  li {
    list-style: none;
  }
}

.section {
}

.circle {
  padding: 10px 20px;
  border-radius: 50%;
  background-color: $deep-green;
  color: $white;
  max-height: 50px;
  z-index: 2;
}

.timeline-steps.row {
  display: flex;
}

.timeline-steps.row .col-2 {
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.timeline-steps.row .col-2::after {
  content: "";
  position: absolute;
  border-left: 3px solid $deep-green;
  z-index: 1;
}

.timeline-steps.row .col-2.bottom::after {
  height: 100%;
  left: 50%;
  top: 0%;
}

.timeline-steps.row .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}

.timeline-steps.row .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}

.timeline div {
  padding: 0;
  height: 40px;
}

.timeline hr {
  border-top: 3px solid $deep-green;
  margin: 0;
  top: 17px;
  position: relative;
}

.timeline .col-2 {
  display: flex;
  overflow: hidden;
}

.timeline .corner {
  border: 3px solid $deep-green;
  width: 100%;
  position: relative;
  border-radius: 15px;
}

.timeline .top-right {
  left: 50%;
  top: -50%;
}

.timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}

.timeline .top-left {
  left: -50%;
  top: -50%;
}

.timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

@media (max-width: 992px) {
  ul.numbered-list {
    columns: 1;
    li {
      padding-left: 0;
    }
  }
}
