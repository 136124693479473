/*intro*/
.intro {
  position: relative;
  min-height: calc(100vh - 60px);
  text-align: center;
  color: $white;
  background: url("../img/background-01.jpg") no-repeat fixed top $deep-green;
  background-size: cover;

  h1 {
    padding-top: 5px;
    margin-bottom: 65px;
    font-family: $roboto;
    font-size: 45px;
    color: $white;
  }
}

.intro__content {
}

.intro__description {
  margin-bottom: 70px;
  max-width: 560px;
  display: inline-block;
}

.intro__controls {
  a {
    margin: 20px 0 0;
  }
}

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 0, 0);

  a {
    position: relative;
    display: block;
    padding: 20px;
  }

  .arrow-down {
    animation: drop 2s ease-in-out infinite;
  }
}

@media (min-width: 768px) {
  .intro__controls {
    a {
      margin: 20px 35px 0;
    }
  }
}
