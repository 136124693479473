.menu-button {
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.hamburger {
  padding: 10px 12px;
  background-color: $white;
  margin: 0;
}

.hamburger-box {
  width: 30px;
  height: 18px;
  position: relative;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: $base-color;
  border-radius: 4px;
  position: absolute;
}

.hamburger-inner {
  &::before, &::after {
    content: "";
    display: block;
  }
  &::before {
    top: -8px;
  }
  &::after {
    bottom: -8px;
  }
}

.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition: transform 0.15s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::after {
      top: -16px;
      transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }
    &::before {
      transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}

.hamburger--collapse:not(.collapsed) {
  .hamburger-inner {
    bottom: -2px;
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition: transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
    }
    &::before {
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
