.slick-prev, .slick-next {
  height: 50px;
  width: 50px;
  top: calc(50% + 53px);
  transform: translate3d(0, -50%, 0);

  &::before {
    content: '';
    display: block;
    position: absolute;
    opacity: 0.8;
    top: 11px;
    height: 25px;
    width: 25px;
    border-top: 3px solid $white;
    border-right: 3px solid $white;
  }

  &:hover::before {
    opacity: 1;
  }
}

.slick-prev {
  left: 0;

  &::before {
    left: 17px;
    transform: rotate(225deg);
  }
}

.slick-next {
  right: 0;

  &::before {
    right: 17px;
    transform: rotate(45deg);
  }
}

.slick-dots {
  left: 0;
}
