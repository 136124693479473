.spinner {
  position: relative;
  margin-left: 10px;
  z-index: 1;

  &::after {
    content: "";
    height: 20px;
    width: 20px;
    background-image: url('../img/loading.gif');
    background-position: center;
    background-size: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
