/*how it works*/
.how-it-works {
  padding-bottom: 0;
}

.how-it-works__content {
  padding: 0 15% 60px;
  background-color: $white;
}

.how-it-works__download-overview {
  padding: 60px 15% 0;
  background-color: $deep-green;
  color: $white;

  h3 {
    color: inherit;
  }
}

.how-it-works__icon {
  margin-bottom: 60px;
}

.how-it-work__sheme {
  padding: 60px 0;
  background-color: $deep-green;
}

@media (min-width: 992px) {
  .how-it-works {
    padding-bottom: 60px;
    background: linear-gradient(to right, $white 0%, $white 50%, $deep-green 50%, $deep-green 100%);
  }

  .how-it-work__sheme {
    background-color: transparent;
  }
}
