/*pricing*/
.pricing {
  background-color: $deep-green;
  color: $white;
  text-align: center;
}

.pricing__header {
  margin: 0 auto 80px;

  h2 {
    color: inherit;
  }
}

.pricing__description {
  max-width: 550px;
  margin: 0 auto 60px;
  font-size: 18px;
}

.pricing__controls {
}

.pricing__table {
  position: relative;
  font-family: $roboto;

  h3 {
    margin-bottom: 25px;
    color: inherit;
    white-space: nowrap;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}

.pricing__table_primary {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: left;

  li {
    background-color: #387c1f;
    margin-bottom: 20px;
    padding: 0 20px;
    line-height: 40px;
    white-space: nowrap;

    &:last-of-type {
      margin-bottom: 10px;
    }

    span {
      display: block;
      max-width: 170px;
    }
  }
}

.pricing__table_secondary {
  color: $black;

  li {
    padding: 0 20px;
    background-color: $white;
    line-height: 60px;
    border-top: 1px solid $light-green;

    &:first-of-type {
      color: $white;
      font-size: 21px;
      text-transform: uppercase;
      background-color: $orange;
      border: none;

      + li {
        border-top: none;
      }
    }

  }
}

@media (min-width: 1200px) {
  .pricing__table {
    padding-left: 190px;
  }
}
