body {
  color: $base-color;
  font: 15px/1.65em $open-sans;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  color: $black;
  font-family: $roboto;
  font-weight: 300;
}

h2 {
  margin-bottom: 60px;
  font-size: 36px;
  text-align: center;
}

h3 {
  margin-bottom: 60px;
  font-size: 30px;
}

h5 {
  font-size: 18px;
}

img {
  max-height: 100%;
  max-width: 100%;
}

section {
  padding: 60px 0;
}
