/*buttons*/
.btn {
  padding: 0 30px;
  font-family: $roboto;
  font-size: 15px;
  font-weight: 300;
  line-height: 48px;
  text-transform: uppercase;
  color: $white;
  min-width: 240px;
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.btn_full {
  width: 100%;
  min-width: unset;
  margin-left: 0;
  margin-right: 0;
}

.btn_orange {
  border-color: transparentize($orange, 0.2);
  background-color: transparentize($orange, 0.2);

  &:hover {
    border-color: $orange;
    background-color: $orange;
  }
}

.btn_green {
  border-color: transparentize($green, 0.2);
  background-color: transparentize($green, 0.2);

  &:hover {
    border-color: $green;
    background-color: $green;
  }
}

.btn_dark-green {
  border-color: transparentize($dark-green, 0.2);
  background-color: transparentize($dark-green, 0.2);

  &:hover {
    border-color: $dark-green;
    background-color: $dark-green;
  }
}

.btn_cadetblue {
  border-color: transparentize($cadetblue, 0.2);
  background-color: transparentize($cadetblue, 0.2);

  &:hover {
    border-color: $cadetblue;
    background-color: $cadetblue;
  }
}

@media (min-width: 768px) {
  .btn_md-default {
    width: auto;
    min-width: 240px;
  }
}
