.mobile-menu:not(.mm-menu_opened) {
  display: none;
}

.mm-listview {
  text-transform: uppercase;
  color: $gray;
}

.mobile-menu, .mm-searchfield input {
  font-family: $roboto;
}

.mm-listitem {
  a {
    display: flex;
    align-items: center;
  }

  i {
    font-size: 25px;
    padding-right: 5px;
  }
}
